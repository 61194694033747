@import "@styles/core/index.scss";

.container {
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  :global {
    img {
      margin: 195px auto 0 auto;
    }
    span {
      margin-top: 89px;
      font-size: 48px;
      line-height: 53px;
      text-align: center;
      letter-spacing: 0.1em;
      color: $primary;
    }
    .not_found {
      margin-top: 10px;
      text-transform: uppercase;
      font-family: "brownregular", serif;
    }
    p {
      margin: 40px auto 0 auto;
      width: 29%;
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.01em;
      color: $primary;
    }
    button {
      margin: 89px auto 0 auto;
    }
    a {
      margin: 25px auto 94px auto;
      width: 240px;
      height: 50px;
      border: 1px solid #938985;
      text-decoration: none;
      display: inline-flex;
      transition: all 0.4s ease-in-out;
      span {
        margin: auto;
        text-transform: uppercase;
        font-family: "brownregular", serif;
        font-size: 15px;
        line-height: 14px;
        text-align: center;
        align-items: center;
        letter-spacing: 0.05em;
        color: #938985;
      }
      &:hover {
        opacity: 70%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    :global {
      img {
        margin-top: 114px;
        height: 95px !important;
        object-fit: contain;
        aspect-ratio: 1/1;
      }
      span {
        margin-top: 45px;
        font-size: 34px;
        line-height: 40px;
      }
      .not_found {
        font-size: 34px;
        line-height: 40px;
      }
      p {
        width: 80%;
        margin: 20px auto 0 auto;
        font-size: 12px;
        line-height: 20px;
      }
      button {
        margin-top: 45px;
        height: 35px;
        width: 140px;
        font-size: 12px;
      }
      a {
        margin-top: 15px;
        margin-bottom: 45px;
        height: 35px;
        width: 140px;
        span {
          font-size: 12px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    :global {
      img {
        margin-top: 154px;
        height: 155px !important;
        object-fit: contain;
        aspect-ratio: 1/1;
      }
      span {
        margin-top: 54px;
      }
      p {
        width: 55%;
      }
      button {
        margin-top: 60px;
      }
      a {
        margin-bottom: 60px;
      }
    }
  }
}
